import ThemeBuilder from 'hsi/classes/ThemeBuilder';
import noResultsImg from 'hsi/themes/bw/img/no-results.png';

export default function elements(theme: ThemeBuilder) {
    const {colors} = theme;

    return {
        searchBarContainerHeight: '64px',
        navBarContainerHeight: '64px',
        filtersDrawerWidth: '300px',
        mentionsDrawerWidth: '424px',
        configDrawerWidth: '424px',
        noResultsImg,

        searchBar: {
            tagBtn: {
                margin: '0px',
            },
            MngSearchButton: {
                margin: '0px',
            },
            MngSearchButtonDisabled: {
                margin: '0px',
            },
        },

        newBadge: {
            padding: '0px 4px',
            fontSize: '8px',
            color: 'white',
            backgroundColor: '#8AC539',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            borderRadius: '2px',
            marginLeft: '8px',
            fontWeight: 700,
            height: '20px',
            lineHeight: '20px',
        },

        cardTable: {
            fontSize: '14px',
            rowHeight: '40px',
            headerHeight: '40px',
            headerSortingColor: colors.accentDefault,
            headerCell: {
                borderBottom: `1px solid ${colors.borders}`,
            },
            row: {
                '&:hover $cell': {
                    backgroundColor: colors.bgHover,
                },
            },
            cell: {
                borderBottom: `1px dotted ${colors.borders}`,
                cursor: 'pointer',
            },
            arrowIcon: {
                width: '16px',
                height: '16px',
            },
            paginationButtons: {
                padding: '12px 12px 0',
                '& .MuiIconButton-root': {
                    border: `1px solid ${colors.uiGrayDarker}`,
                    padding: '4px',
                    '& svg': {
                        fill: colors.icons,
                    },
                    '@media print': {
                        display: 'none',
                    },
                    '.printMedia &': {
                        display: 'none',
                    },
                },
                '& .MuiIconButton-root.Mui-disabled': {
                    backgroundColor: colors.bgDisabled,
                    border: '0',
                    '& svg': {
                        fill: colors.textDisabled,
                    },
                },
            },
        },

        topAuthorsCard: {
            indexColor: colors.textSubtle,
            locationColor: colors.textSubtle,
        },

        topHashtagsCard: {
            indexColor: colors.textSubtle,
            locationColor: colors.textSubtle,
        },
    };
}
